// i18next-extract-mark-ns-start documents

import React, { FC } from 'react'
import { INewsContentBlock } from 'src/components/news/article/news-article-types'
import NewsHtmlBlock from 'src/components/news/article/NewsHtmlBlock'
import NewsAssetGalleryBlock from 'src/components/news/article/NewsAssetGalleryBlock'
import NewsExternalVideoBlock from 'src/components/news/article/NewsExternalVideoBlock'
import NewsInternalVideoBlock from 'src/components/news/article/NewsInternalVideoBlock'
import NewsRelatedArticlesBlock from 'src/components/news/article/NewsRelatedArticlesBlock'
import RelatedDocuments from 'src/components/document/RelatedDocuments'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import config from 'src/../tailwind.config'
import FifaRedEmbed from './FifaRedEmbed'

interface INewsArticleBodyProps {
  contentBlocks: INewsContentBlock[]
}

const NewsArticleBody: FC<INewsArticleBodyProps> = ({ contentBlocks }) => {
  const { t } = useTranslation('news')

  const newsBlockElement = (block: INewsContentBlock): JSX.Element => {
    switch (block.apiKey) {
      case 'news_html':
        return (
          <NewsHtmlBlock
            key={block.id}
            html={block.htmlContent}
            className="mb-10"
          />
        )
      case 'news_asset_gallery':
        return (
          <NewsAssetGalleryBlock
            key={block.id}
            assets={block.assets}
            className="mb-10"
          />
        )
      case 'related_document':
        return (
          <RelatedDocuments
            key={block.id}
            items={block.relatedDocuments}
            header={t('Related Documents')}
            color={config.theme.extend.colors.navyBlueGray}
            className="mb-10"
          />
        )
      case 'news_external_video':
        return (
          <NewsExternalVideoBlock
            key={block.id}
            videoId={block.video.providerUid}
            className="mb-10"
          />
        )
      case 'news_internal_video':
        return (
          <NewsInternalVideoBlock
            key={block.id}
            videoUrl={block.videoUrl || block.video.url}
            posterUrl={block.poster.url}
            className="mb-10"
          />
        )
      case 'news_fifa_red_embed':
        return (
          <FifaRedEmbed key={block.id} link={block.link} className="mb-10" />
        )
      case 'news_related_article':
        return (
          <NewsRelatedArticlesBlock
            key={block.id}
            items={block.relatedArticles}
            className="mb-10"
          />
        )
      default:
        return null
    }
  }

  const blocks = contentBlocks.map(newsBlockElement)

  return <>{blocks}</>
}

export default NewsArticleBody
