import React, { FC } from 'react'
import { IImage } from 'src/components/news/news-types'

interface INewsAssetGalleryBlock {
  assets: IImage[]
  className?: string
}

const NewsAssetGalleryBlock: FC<INewsAssetGalleryBlock> = ({
  assets,
  className,
}) => (
  <div className={className}>
    <img src={assets[0].url} alt={assets[0].alt} className="w-full" />
  </div>
)

export default NewsAssetGalleryBlock
