import React, { FC } from 'react'
import { IImage, IVideo } from 'src/components/news/news-types'
import toShortNewsDate from 'src/helpers/news'
import themeColors from 'src/helpers/theme-colors'
import styled from 'styled-components'
import tw from 'twin.macro'
import 'video-react/dist/video-react.css'
import { Player } from 'video-react'

interface INewsArticleHeaderProps {
  date: string
  title: string
  image: IImage
  video: IVideo
  locale: string
}

const ImageContainer = styled.div`
  background: linear-gradient(${themeColors.grayBlue}, ${themeColors.grayBlue});
  background-size: 100% 50%;
  ${tw`bg-top bg-no-repeat`}
`

const NewsArticleHeader: FC<INewsArticleHeaderProps> = ({
  date,
  title,
  image,
  video,
  locale,
}) => {
  const getMediaContent = () => {
    if (video) {
      return (
        <Player poster={image.url}>
          <source src={video.url} />
        </Player>
      )
    }

    return <img src={image.url} alt={image.alt} className="w-full" />
  }

  return (
    <>
      <div className="bg-grayBlue text-2xl lg:text-4xl">
        <div className="container text-white font-thin tracking-widest pt-8 lg:pt-16">
          {toShortNewsDate(date, locale)}
        </div>
        <div className="container text-brightYellow font-medium pt-5 pb-10 lg:pb-14 lg:pt-10">
          <div className="">{title}</div>
        </div>
      </div>
      <ImageContainer>
        <div className="container">{getMediaContent()}</div>
      </ImageContainer>
    </>
  )
}

export default NewsArticleHeader
